<template>
  <b-overlay :show="$apollo.loading" rounded="sm">
    <div class="card card-custom card-stretch" v-if="systemLotList">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title flex-column">
          <div class="d-flex align-items-center" style="height: 35px">
            <span class="card-label font-weight-bolder text-dark">
              รายการล็อต
            </span>
          </div>
        </h3>
      </div>
      <div
        class="card-toolbar d-flex justify-content-end pr-8 align-items-center"
      >
        <div class="input-group input-group-solid mr-4" style="width: 280px">
          <input
            type="text"
            class="form-control"
            placeholder="ค้นหา"
            @input="debounceInput"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/General/Search.svg" />
              </span>
            </span>
          </div>
        </div>
        <router-link
          :to="`${$route.path}/create`"
          class="btn btn-success font-weight-bolder font-size-sm mr-4"
        >
          <span class="svg-icon svg-icon-md svg-icon-white">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
          เพิ่มล็อต
        </router-link>
        <button
          @click="$apollo.queries.systemLotList.refetch()"
          class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
        >
          <i class="ki ki-refresh"></i>
        </button>
      </div>
      <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
          <table
            class="table table-sm table-head-bg table-borderless table-vertical-center"
          >
            <thead>
              <tr>
                <th class="pl-4 text-nowrap">หมายเลข</th>
                <th class="pl-4">ชื่อ</th>
                <th class="text-center">ขนส่ง</th>
                <th class="text-center">เส้นทาง</th>
                <th class="text-center text-nowrap">เข้าโกดัง</th>
                <th class="text-center text-nowrap">ออกโกดัง</th>
                <th class="text-center text-nowrap">ถึงโกดัง</th>
                <th class="text-center">จำนวน</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in systemLotList.records" v-bind:key="i">
                <td class="pl-4">
                  {{ item.lotId }}
                </td>
                <td class="pl-4">
                  {{ item.lotName }}
                </td>
                <td class="text-center">
                  {{ item.ship_by === "ek" ? "รถ" : "เรือ" }}
                </td>
                <td class="text-center">
                  {{ item.type_name }}
                </td>
                <td class="text-center">
                  {{ format_date(item.send_to_warehouse_china) }}
                </td>
                <td class="text-center">
                  {{ format_date(item.send_out_china) }}
                </td>
                <td class="text-center">
                  {{ format_date(item.send_to_warehouse_th) }}
                </td>
                <td class="text-center">{{ item.numOfTransport }}</td>
                <td class="text-right pr-4 text-nowrap">
                  <button
                    class="btn btn-icon btn-danger btn-sm mr-2"
                    @click="deleteLot(item._id, item.lotName)"
                  >
                    <span class="la la-trash icon-lg" />
                  </button>
                  <button
                    class="btn btn-icon btn-primary btn-sm mr-2"
                    @click="oneDrive(item)"
                  >
                    <span class="svg-icon svg-icon-md">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Design/Stamp.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </button>
                  <router-link
                    :to="`/admin/lot/${item.lotId}`"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <inline-svg
                        src="/media/svg/icons/Navigation/Arrow-right.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </router-link>
                </td>
              </tr>
              <tr
                class="text-center"
                v-if="
                  systemLotList &&
                    systemLotList.records &&
                    !systemLotList.records.length
                "
              >
                <td colspan="10" class="pt-8">ไม่พบล็อต</td>
              </tr>
            </tbody>
          </table>
        </div>
        <b-pagination
          v-model="page"
          class="mt-4"
          size="md"
          v-if="systemLotList && systemLotList.recordsData"
          :total-rows="systemLotList.recordsData.recordsFiltered"
          :per-page="pageSize"
        ></b-pagination>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { GET_LOT_LIST, DELETE_LOT, ONE_DRIVE_API } from "@/graphql/admin/lot";
import { WEBSITE } from "@/config";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import debounce from "debounce";

export default {
  created() {
    document.title = `รายการล็อต | ${WEBSITE}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `ตั้งค่า`, route: "/admin/setting" },
      { title: `รายการล็อต` }
    ]);
  },
  data() {
    return {
      pageSize: 10,
      page: 1,
      search: ""
    };
  },
  methods: {
    poll() {
      setInterval(() => {
        this.$apollo.queries.systemLotList.refetch();
      }, 6000);
    },
    format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
      return "-";
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    },
    debounceInput: debounce(function(e) {
      this.search = e.target.value;
      this.page = 1;
    }, 400),
    async oneDrive(item) {
      const confirm = await Swal.fire({
        title: "ต้องการอัพเดทข้อมูลล็อต ?",
        icon: "question",
        html: `ยืนยันการอัพเดทล็อต ${item.lotName} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { oneDriveApi }
      } = await this.$apollo.mutate({
        mutation: ONE_DRIVE_API,
        variables: {
          _id: item._id
        }
      });
      if (!oneDriveApi.isSuccess) {
        this.$message.error(oneDriveApi.message);
      } else {
        this.$message.success(oneDriveApi.message);
        this.$apollo.queries.systemLotList.refetch();
      }
    },
    getStatusText(status) {
      switch (status) {
        case false:
          return "ไม่ได้ทำงาน";
        case true:
          return "กำลังทำงาน...";
        default:
          return "ไม่ได้ทำงาน";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case false:
          return "default";
        case true:
          return "primary";
        default:
          return "primary";
      }
    },
    async toggleShow(bank_id, is_show) {
      const {
        data: { delete_lot }
      } = await this.$apollo.mutate({
        mutation: DELETE_LOT,
        variables: {
          input: {
            _id: bank_id,
            is_show
          }
        }
      });
      if (!delete_lot.isSuccess) {
        this.$message.error(delete_lot.message);
      } else {
        this.$apollo.queries.systemLotList.refetch();
      }
    },
    async deleteLot(bank_id, name) {
      const confirm = await Swal.fire({
        title: "ลบล็อต ?",
        icon: "question",
        html: `ยืนยันการลบล็อต ${name} ใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { delete_lot }
      } = await this.$apollo.mutate({
        mutation: DELETE_LOT,
        variables: { _id: bank_id }
      });
      if (!delete_lot.isSuccess) {
        this.$message.error(delete_lot.message);
      } else {
        this.$message.success(delete_lot.message);
        this.$apollo.queries.systemLotList.refetch();
      }
    }
  },
  apollo: {
    systemLotList: {
      query: GET_LOT_LIST,
      notifyOnNetworkStatusChange: true,
      variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search
        };
      }
    }
  }
};
</script>
